// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-policy-js": () => import("./../../../src/pages/access-policy.js" /* webpackChunkName: "component---src-pages-access-policy-js" */),
  "component---src-pages-bike-warranty-js": () => import("./../../../src/pages/bike-warranty.js" /* webpackChunkName: "component---src-pages-bike-warranty-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-city-cycle-js": () => import("./../../../src/pages/city-cycle.js" /* webpackChunkName: "component---src-pages-city-cycle-js" */),
  "component---src-pages-faq-bike-js": () => import("./../../../src/pages/faq/bike.js" /* webpackChunkName: "component---src-pages-faq-bike-js" */),
  "component---src-pages-faq-content-js": () => import("./../../../src/pages/faq/content.js" /* webpackChunkName: "component---src-pages-faq-content-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-returns-js": () => import("./../../../src/pages/faq/returns.js" /* webpackChunkName: "component---src-pages-faq-returns-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-partner-pages-data-json-alias-tsx": () => import("./../../../src/pages/{partnerPagesDataJson.alias}.tsx" /* webpackChunkName: "component---src-pages-partner-pages-data-json-alias-tsx" */),
  "component---src-pages-prime-cycle-js": () => import("./../../../src/pages/prime-cycle.js" /* webpackChunkName: "component---src-pages-prime-cycle-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-studios-js": () => import("./../../../src/pages/studios.js" /* webpackChunkName: "component---src-pages-studios-js" */),
  "component---src-pages-term-of-use-js": () => import("./../../../src/pages/term-of-use.js" /* webpackChunkName: "component---src-pages-term-of-use-js" */),
  "component---src-pages-the-bike-js": () => import("./../../../src/pages/the-bike.js" /* webpackChunkName: "component---src-pages-the-bike-js" */),
  "component---src-pages-try-stryde-js": () => import("./../../../src/pages/try-stryde.js" /* webpackChunkName: "component---src-pages-try-stryde-js" */),
  "component---src-pages-vibe-ride-js": () => import("./../../../src/pages/vibe-ride.js" /* webpackChunkName: "component---src-pages-vibe-ride-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

